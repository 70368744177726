import React from 'react'
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

import {
    Typography,
    Grid,
    useTheme,
    makeStyles, useMediaQuery
} from "@material-ui/core"
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import StarIcon from '@material-ui/icons/Star';
import RoomIcon from '@material-ui/icons/Room';

import 'react-vertical-timeline-component/style.min.css';

const useStyles = makeStyles(theme => ({
    root: {
        '&::before': {
            background: `${theme.palette.text.disabled}!important`
        }
    },
    location: {
        marginTop: "0px!important",
        marginBottom: "0px!important",
        marginLeft: "4px!important",
        padding: 0,
        fontSize: "14dp",
    },
    locationRoot: {
        marginBottom: theme.spacing(2),
    },
    date: {
        fontWeight: "600!important",
        fontSize: "1rem!important",
        color: theme.palette.text
    },
    color: {
        color: `${theme.palette.grey["400"]}!important`
    },
    white: {
        color: `${theme.palette.grey["400"]}!important`
    },
    light: {
        color: `${theme.palette.grey["50"]}!important`
    },
    tightMargin: {
        margin: "5px 0",
    },
}));

export default function EducationAndExperienceTimeline({ cards }) {
    const classes = useStyles()
    const { palette } = useTheme()
    const isLgDown = useMediaQuery(theme => theme.breakpoints.down('md'))
    const isThemeDark = palette.type === 'dark'
    const contentFilter = isThemeDark
      ? palette.accents.second.dark
      : palette.accents.second.main
    const iconStyle = isThemeDark
      ? palette.common.black
      : palette.common.black

    return (
      <VerticalTimeline animate={!isMobile} className={classes.root} layout={isLgDown ? '1-column-left' : '2-columns'}>
          {cards.map(ele =>
            <VerticalTimelineElement
              key={ele.title}
              contentStyle={{background: contentFilter}}
              date={ele.date}
              dateClassName={classes.date}
              iconStyle={{background: contentFilter, borderColor: `${iconStyle}!important`}}
              icon={ele.icon === "school"
                ? <SchoolIcon color={"action"}/>
                : <WorkIcon color={"action"}/>
              }
            >
                {ele.location && (
                  <Grid
                    container
                    alignContent={"center"}
                    className={classes.locationRoot}
                  >
                      <Grid item>
                          <RoomIcon fontSize={"small"}/>
                      </Grid>
                      <Grid item>
                          <Typography
                            variant={"subtitle2"}
                          >
                              {ele.location}
                          </Typography>
                      </Grid>
                  </Grid>
                )}
                <Typography
                  variant={"h6"}
                  color={"textPrimary"}
                >
                    <strong>
                        {ele.title}
                    </strong>
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  color={"textSecondary"}
                  gutterBottom
                >
                    {ele.subTitle}
                </Typography>
                {ele.para && (
                  <ul>
                      {ele.para.map(ele =>
                        <Typography
                          variant={"body1"}
                          component={"li"}
                        >
                            {ele}
                        </Typography>
                      )}
                  </ul>
                )}
                <br/>
                {ele.aside && (
                  <Typography
                    variant={"body2"}
                  >
                      {ele.aside}
                  </Typography>
                )}
            </VerticalTimelineElement>
          )}
          <VerticalTimelineElement
            iconStyle={{ background: contentFilter, color: palette.grey["700"] }}
            icon={<StarIcon color={"action"}/>}
          />
      </VerticalTimeline>
    )
}

EducationAndExperienceTimeline.propTypes = {
    cards: PropTypes.array.isRequired,
}
