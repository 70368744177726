import React from 'react'
import classNames from "classnames";
import { isMobile } from "react-device-detect"
import gsap from "gsap"

import {makeStyles} from "@material-ui/core/styles"
import AdjustIcon from '@material-ui/icons/Adjust';

import {useCustomScrollTrigger} from "../Providers/ScrollTriggerProvider";

const useStyles = makeStyles(theme => ({
    iconStyle: {
        width: "40px",
        height: "40px",
        marginTop: "83vh",
        marginLeft: "auto",
        marginRight: "auto",
        position: "fixed",
        color: theme.palette.grey["200"],
        left: 0,
        right: 0,
        zIndex: 1000,
    },
    hidden: {
        display: "none!important",
    },
}))

export default function BreathingSwipeDownIcon() {
    const classes = useStyles()
    const {scrollTrigger} = useCustomScrollTrigger();
    let intro = React.useRef(null)

    /* Icon classes */
    const iconClasses = classNames({
        [classes.iconStyle]: true,
        [classes.hidden]: scrollTrigger || isMobile
    })

    /* Animation Vars */
    const textIntro = elem => gsap.to(elem, {
        delay: 1,
        repeatDelay: 0.5,
        opacity: 0,
        repeat: -1,
        ease: "sine.out",
        duration: 4,
        y: 60,
    })

    /* UseEffect */
    React.useEffect(() => { textIntro(intro) }, [])

    return (
        <AdjustIcon
            ref={el => intro = el}
            className={iconClasses}
        />
    );
}
