import React from 'react';

import { Link, Typography, makeStyles } from "@material-ui/core"

// import DefaultHelmet from "../components/Helmet/DefaultHelmet"

import { URLS } from "../assets/strings/urls"
import { NotFoundData } from "../assets/data/pages/NotFoundData"

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12),
    }
  },
  link: {
    color: theme.palette.accents.first.main,
    "&:hover": {
      color: theme.palette.accents.first.light,
    }
  }
}))

export default function NotFoundPage() {
  const classes = useStyles()
  const { hero } = NotFoundData
  return (
    <>
      {/*<DefaultHelmet title={title} />*/}
      <main className={classes.root}>
        <Typography
          variant={"h4"}
          component={"h1"}
          color={"textPrimary"}
          gutterBottom
        >
          {hero.header}
        </Typography>
        <Typography
          variant={"h6"}
          component={"p"}
          color={"textSecondary"}
        >
          {hero.subHeader}
          <Link className={classes.link} href={URLS.ROOT}>
            {hero.link}
          </Link>
        </Typography>
      </main>
    </>
  );
}
