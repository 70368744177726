import { fullName } from "../global"

export const FooterData = {
  left: {
    header: fullName,
  },
  right: {
    email: "Email:",
    phone: "Mobile:",
  },
}