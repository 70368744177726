import React from "react";
import PropTypes from "prop-types"

import {withStyles} from "@material-ui/styles";
import {Tooltip} from "@material-ui/core";

const InternalCustomTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.grey["900"],
        fontSize: 13,
    },
}))(Tooltip);

export default function CustomTooltip({ title, children }) {
    return (
        <InternalCustomTooltip title={title} placement="top">
            {children}
        </InternalCustomTooltip>
    )
}

CustomTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
}
