import React from 'react'
import PropTypes from "prop-types"
import {Helmet} from "react-helmet";

import {canonicalUrl, fullName} from "../../assets/data/global";
import {LandingData} from "../../assets/data/pages/LandingData";

export default function DefaultHelmet({title}) {
  const description = `The web resume of ${fullName}`
  const keywords = "React, JavaScript, Resume"
  return (
    <Helmet>
      <title>{title}</title>

      <link rel="canonical" href={canonicalUrl} />
      <link rel="icon" type={"image/png"} sizes={"192x192"} href={"/android-chrome-192x192.png"} />
      <link rel="icon" type={"image/png"} sizes={"512x512"} href={"/android-chrome-512x512.png"} />
      <link rel="apple-touch-icon" type={"image/png"} sizes={"180x180"} href={"/apple-touch-icon.png"} />
      <link rel="icon" type={"image/ico"} sizes={"16x16"} href={"/favicon.ico"} />
      <link rel="icon" type={"image/png"} sizes={"16x16"} href={"/favicon-16x16.png"} />
      <link rel="icon" type={"image/png"} sizes={"32x32"} href={"/favicon-32x32.png"} />

      <meta charSet={"UTF-8"} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={fullName} />
      <meta name="viewport" content={"width=device-width, initial-scale=1.0"} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_AU" />
    </Helmet>
  )
}

DefaultHelmet.defaultProps = {
  title: LandingData.title,
}

DefaultHelmet.propTypes = {
  title: PropTypes.string,
}
