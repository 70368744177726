import React from 'react'
import PropTypes from "prop-types"

import {Grid, useTheme} from "@material-ui/core";

import OnHoverFlipCard from "../../components/Card/OnHoverFlipCard";

export default function Hobbies({ data }) {
  const theme = useTheme()
  const isThemeDark = theme.palette.type === 'dark'
  const contentFilter = isThemeDark
    ? theme.palette.accents.fourth.dark
    : theme.palette.accents.fourth.main
  return (
    <Grid
      container
      direction={"row"}
      justify={"space-between"}
      alignItems={"center"}
      alignContent={"center"}
    >
      {
        data.map((ele, key) => (
          <Grid xs={12} sm={6} md={6} lg={3} item key={key}>
            {/*<Grid item key={key}>*/}
            <OnHoverFlipCard
              frontImage={ele.image}
              backText={ele.text}
              flipDirection={ele.flipDirection}
              color={contentFilter}
            />
          </Grid>
        ))
      }
    </Grid>
  )
}

Hobbies.propTypes = {
  data: PropTypes.array.isRequired,
}
