import React from "react"
import classNames from "classnames"

import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from "@material-ui/core"

import { NavLinkData } from "../../assets/data/components/NavLinkData"

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    right: "0",
    height: "100vh",
    width: theme.drawer.width,
    boxShadow: theme.shadows[3],
    transition: `${theme.transitions.duration.enteringScreen} ${theme.transitions.easing.easeIn}`,
    backgroundColor: theme.palette.grey['900']
  },
  list: {
    margin: theme.spacing(4, 0),
  },
  listItem: {
    color: theme.palette.grey["300"]
  },
  icon: {
    marginRight: theme.spacing(4)
  },
  first: {
    "&:hover": {
      color: theme.palette.accents.first.main
    }
  },
  second: {
    "&:hover": {
      color: theme.palette.accents.second.main,
    }
  },
  third: {
    "&:hover": {
      color: theme.palette.accents.third.main,
    }
  },
  fourth: {
    "&:hover": {
      color: theme.palette.accents.fourth.main,
    }
  },
  fifth: {
    "&:hover": {
      color: theme.palette.accents.fifth.main,
    }
  },
}));

function AppDrawer({open, handleDrawerToggle}) {
  const classes = useStyles()
  return (
    <Drawer
      variant={"temporary"}
      anchor={"right"}
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
      onClose={handleDrawerToggle}
    >
      <List className={classes.list}>
        {NavLinkData.map(ele =>
          <ListItem
            button
            key={ele.icon}
            target="_blank"
            rel="noopener noreferrer"
            component="a"
            href={ele.href}
            className={classNames(classes[ele.color], classes.listItem)}
            onClick={handleDrawerToggle}
          >
            <ele.icon className={classes.icon}/>
            <ListItemText primary={ele.tip}/>
          </ListItem>
        )}
      </List>
    </Drawer>
  )
}

export default AppDrawer