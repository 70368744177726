import React from 'react'
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect"
import {animated, useSpring} from 'react-spring'

import {
    Avatar,
    Grid,
    Card,
    CardActionArea,
    useTheme,
    makeStyles, Typography
} from "@material-ui/core"


const useStyles = makeStyles(theme => ({
    card: {
        width: "100%",
        maxWidth: "300px",
        padding: theme.spacing(4, 0),
        margin: "0 auto",
        "&:hover": {
            boxShadow: "0px 30px 100px -10px rgba(0, 0, 0, 0.4)"
        },
    },
    avatar: {
        margin: theme.spacing(0, "auto", 2, "auto"),
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    tightLineHeight: {
        lineHeight: ".5rem",
        fontWeight: 400,
    },
    margins: {
        padding: "8px",
    },
}))

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

export default function SkillsAndTools({ cards }) {
    const classes = useStyles()
    const [animatedProps, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))
    const theme = useTheme()
    const isThemeDark = theme.palette.type === 'dark'
    const contentFilter = isThemeDark
      ? theme.palette.accents.fifth.dark
      : theme.palette.accents.fifth.main

    const rootCard = ele => (
      <Card className={classes.card} style={{background: contentFilter}}>
          <CardActionArea>
              <Avatar src={ele.logo} className={classes.avatar}/>
              <Typography
                variant={"h6"}
                component={"p"}
                align={"center"}
                gutterBottom
              >
                  <strong>
                      {ele.title}
                  </strong>
              </Typography>
              {ele.list.map(ele =>
                <Typography
                  key={ele}
                  variant={"subtitle1"}
                  align={"center"}
                >
                    {ele}
                </Typography>
              )}
          </CardActionArea>
      </Card>
    )

    return (
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justify={"space-evenly"}
      >
          {cards.map((ele, key) =>
            <Grid xs={12} sm={6} md={6} lg={3} item key={key} className={classes.margins}>
                {isMobile
                  ? rootCard(ele)
                  : (
                    <animated.div
                      onMouseMove={({clientX: x, clientY: y}) => set({xys: calc(x, y)})}
                      onMouseLeave={() => set({xys: [0, 0, 1]})}
                      style={{transform: animatedProps.xys.interpolate(trans)}}
                    >
                        {rootCard(ele)}
                    </animated.div>
                  )
                }
            </Grid>
          )}
      </Grid>
    )
}

SkillsAndTools.propTypes = {
    cards: PropTypes.array.isRequired,
}
