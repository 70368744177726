import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import {makeStyles} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    sectionTop: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(30)
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(60)
        }
    },
    sectionBottom: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(30)
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(60),
        }
    },
}));

export default function Section({ children, top, header, subHeader }) {
    const classes = useStyles()
    const sectionClasses = classNames({
        [classes.sectionTop]: true,
        [classes.sectionBottom]: !top,
    })
    return (
        <section className={sectionClasses}>
            <Typography
              variant={"h4"}
              component={"h3"}
              align={"center"}
              color={"textPrimary"}
            >
                {header}
            </Typography>
            <Typography
              variant={"h5"}
              component={"h4"}
              align={"center"}
              color={"textSecondary"}
              gutterBottom
            >
                {subHeader}
            </Typography>
            <br/>
            {children}
        </section>
    );
}
Section.defaultProps = {
    top: false
};

Section.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.string.isRequired,
    subHeader: PropTypes.string.isRequired,
    top: PropTypes.bool
}
