import { email, phone } from "../data/global";

const URLS = {
    ROOT: "/",
    PAGE_NOT_FOUND: "/404",

    PAPER_RESUME: "https://www.canva.com/design/DAFv5cKFJM4/KlGGuEp-devz43EvqHMabg/edit?utm_content=DAFv5cKFJM4&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",

    GITHUB_PROFILE: "https://github.com/s3539822",
    LINKEDIN_PROFILE: "https://www.linkedin.com/in/jeremy-bereszkowski-999ba016a/",
}

export const MAIL_LINK = `mailto:${email}`;
export const TEL_LINK = `tel:${phone}`;

export {
    URLS
}
