import React from 'react';

import { Container } from "@material-ui/core"

import DefaultPageLayout from "../layouts/DefaultPageLayout"

import AboutMe from "../page-sections/landing/AboutMe"
import EducationAndExperienceTimeline from "../page-sections/landing/EducationAndExperienceTimeline"
import Projects from "../page-sections/landing/Projects"
import SkillsAndTools from "../page-sections/landing/SkillsAndTools"
import Hobbies from "../page-sections/landing/Hobbies"

import Parallax from "../components/Parallax/Parallax"
import BreathingSwipeDownIcon from "../components/Animated/BreathingSwipeDownIcon"
import Section from "../components/Containers/Section"
import ContactForm from "../components/Forms/ContactForm"

import { LandingData } from "../assets/data/pages/LandingData"

export default function LandingPage() {
  return (
    <DefaultPageLayout>
      <BreathingSwipeDownIcon />
      <Parallax
        image={LandingData.hero.background}
        header={LandingData.hero.header}
        body={LandingData.hero.subHeader}
      />
      <Container maxWidth={"lg"}>
        <Section
          top
          header={LandingData.aboutMe.header}
          subHeader={LandingData.aboutMe.subHeader}
        >
          <AboutMe
            avatar={LandingData.aboutMe.avatar}
            data={LandingData.aboutMe.about}
          />
        </Section>
        <Section
          top
          header={LandingData.education.header}
          subHeader={LandingData.education.subHeader}
        >
          <EducationAndExperienceTimeline
            cards={LandingData.education.cards}
          />
        </Section>
        <Section
          top
          header={LandingData.projects.header}
          subHeader={LandingData.projects.subHeader}
        >
          <Projects
            cards={LandingData.projects.cards}
          />
        </Section>
        <Section
          top
          header={LandingData.skills.header}
          subHeader={LandingData.skills.subHeader}
        >
          <SkillsAndTools
            cards={LandingData.skills.cards}
          />
        </Section>
        <Section
          top
          header={LandingData.hobbies.header}
          subHeader={LandingData.hobbies.subHeader}
        >
          <Hobbies
            data={LandingData.hobbies.data}
          />
        </Section>
        <Section
          header={LandingData.contactForm.header}
          subHeader={LandingData.contactForm.subHeader}
        >
          <ContactForm/>
        </Section>
      </Container>
    </DefaultPageLayout>
  );
}
