import React from "react";
import PropTypes from "prop-types"

import { Typography, makeStyles } from "@material-ui/core"

import {hexToRgb} from "../../util/hexToRgb";

const useStyles = makeStyles(theme => ({
    parallax: {
        backgroundSize: "cover",
        backgroundPosition: "top center",
        height: "115vh",
        maxHeight: "1600px",
        minWidth: "100%",
        position: "relative",
        margin: "0",
        padding: "0",
        display: "flex",
        alignItems: "center",
        "&:before": {
            background: `rgba(${hexToRgb(theme.palette.common.black)}, ${theme.palette.type === 'dark' ? 0.7 : 0.5})`
        },
        "&:after,&:before": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: "''"
        }
    },
    container: {
        color: theme.palette.type === 'dark'
          ? theme.palette.grey["200"]
          : theme.palette.grey["100"],
        position: "absolute",
        width: "100vw",
        zIndex: "10",
        marginTop: "-15vh",
    },
    title: {
        textTransform: "uppercase",
    },
    subTitle: {
        maxWidth: "55%",
        margin: "0 auto",
    }
}))

export default function Parallax({ image, header, body }) {
    const classes = useStyles()
    return (
      <div
        className={classes.parallax}
        style={{
            backgroundImage: `url(${image})`,
        }}
      >
          <div className={classes.container}>
              <Typography
                variant={"h3"}
                component={"h1"}
                align={"center"}
                className={classes.title}
              >
                  {header}
              </Typography>
              <Typography
                variant={"h6"}
                component={"h2"}
                align={"center"}
                className={classes.subTitle}
              >
                  {body}
              </Typography>
          </div>
      </div>

    )
}

Parallax.propTypes = {
    parallaxImage: PropTypes.string,
    parallaxHeader: PropTypes.string,
    parallaxBody: PropTypes.string,
}
