import React from 'react'
import PropTypes from 'prop-types'
import classNames from "classnames"

import { Typography, makeStyles } from "@material-ui/core"

import {MAIL_LINK, TEL_LINK} from "../../assets/strings/urls";

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: "none",
        "&:hover": {
            color: theme.palette.accents.third.light,
            textDecoration: "underline",
        }
    },
}));

export default function DataLink({ data, label, mobile, mail }) {
    const classes = useStyles()
    const href = mail
      ? MAIL_LINK
      : mobile
        ? TEL_LINK
        : data

    return (
      <Typography
        variant={"subtitle1"}
        component={"p"}
      >
          {label}{' '}
          <a href={href} className={classes.link}>
              {data}
          </a>
      </Typography>
    )
}

DataLink.defaultProps = {
    mobile: false,
    mail: false,
}

DataLink.propTypes = {
    data: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    mobile: PropTypes.bool,
    mail: PropTypes.bool,
}
