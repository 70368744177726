import React from 'react'
import PropTypes from "prop-types";
import classNames from 'classnames'

import { Button, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    button: {
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1, 3),
        margin: theme.spacing(1, 0),
        textTransform: "uppercase",
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(0, 0),
        }
    },
    pinkHover: {
        "&:hover, &:focus": {
            color: theme.palette.accents.first.main,
        }
    }
}));

const BlockButton = React.forwardRef((props, ref) => {
    const {children, accentColor, colorHover, ...rest} = props
    const classes = useStyles()

    return (
      <Button
        {...rest}
        ref={ref}
        className={classNames(classes.button, colorHover ? classes.pinkHover : null)}
        style={{backgroundColor: accentColor}}
        target="_blank"
        rel="noopener noreferrer"
      >
          {children}
      </Button>
    )
})

BlockButton.propTypes = {
    children: PropTypes.node,
    accentColor: PropTypes.string,
};

export default BlockButton
