import {fullName, headerString} from "../global";

/*Hobbies*/
const Snowboard = "/hobbies/Snowboard.png"
const Yoga = "/hobbies/Yoga.png"
const Bicycle = "/hobbies/Bicycle.png"
const Camping = "/hobbies/Camping.png"

/*Logos*/
const JavaScriptLogo = "/logos/JavaScript.png"
const CloudLogo = "/logos/Cloud.jpg"
const ToolsLogo = "/logos/Tools.jpg"
const JavaLogo = "/logos/Java.png"

const Avatar = "/core/avatar.png"

/* Landing Background */
const background = "/core/background.jpg"

export const LandingData = {
    title: headerString,
    hero: {
        header: fullName,
        subHeader: "Full-Stack Developer and Hardware Engineer",
        background: background,
    },
    aboutMe: {
        header: "About Me",
        subHeader: "A snapshot of myself",
        about: {
            header: `${fullName},`,
            subHeader: "Final year Bachelor of Engineering (Computer Systems and Networking) with Honours/Bachelor of Computer Science student at RMIT University.",
            para: [
                "A highly organized and detail-oriented individual with a strong focus on consumer-facing interfaces and design.",
                "Experience across a wide range of technologies including ReactJS, Java/JUnit, CI/CD, Google Cloud and AWS.",
                "Studying a double degree across both software and hardware has provided me with a unique perspective when approaching new projects, one that I look forward to bringing into all aspects of my work.",
            ]
        },
        avatar: Avatar,
    },
    projects: {
        header: "Work Portfolio",
        subHeader: "Product and Projects",
        cards: [
            {
                title: "Malki New",
                subTitle: "NextJS, MaterialUI",
                para: "A static SPA built using NextJS and MaterialUI for a local private practice counsellor based in Melbourne, Australia. Continuous integration is provided by GitHub and Vercel, hosting is provided by Vercel.",
                date: "FEB/21 - MAR/21",
                text1: "Visit Webpage",
                href1: "https://malkinew.com.au/",
                text2: "Source Code",
                href2: "https://github.com/s3539822/malki_new_personal_website",
            },
            {
                title: "MoveWell Physiotherapy",
                subTitle: "NextJS, MaterialUI",
                para: "A static SPA built in NextJS using MaterialUI for a Physiotherapy Practice based in Melbourne, Australia. Continuous integration is provided by GitHub and Vercel, hosting is provided by Vercel.",
                date: "OCT/20 - FEB/21",
                text1: "Visit Webpage",
                href1: "https://movewellmelbourne.com.au/",
                text2: "Source Code",
                href2: "https://github.com/s3539822/MoveWellPhysio",
            },
            {
                title: "PICD Software Management Tool",
                subTitle: "ReactJS,  ExpressJS, SQL, Docker",
                para: "A static SPA built in ReactJS using an ExpressJS backend to communicate with a SQL database. The project was containerized using docker and is hosted via GitHub packages.",
                date: "MAR/20 - OCT/20",
                text2: "Source Code",
                href2: "https://github.com/s3539822/PICD-Capstone",
            },
        ]
    },
    skills: {
        header: "Skills and Tools",
        subHeader: "What I'm working with right now",
        cards: [
            {
                title: "Javascript",
                logo: JavaScriptLogo,
                list: [
                    "ReactJS",
                    "NextJS",
                    "ExpressJS",
                ],
            },
            {
                title: "Java",
                logo: JavaLogo,
                list: [
                    "Java 9",
                    "Android",
                    "JUnit",
                ],
            },
            {
                title: "Cloud",
                logo: CloudLogo,
                list: [
                    "AWS",
                    "Google Cloud",
                    "Vercel",
                ],
            },
            {
                title: "Tools",
                logo: ToolsLogo,
                list: [
                    "Docker",
                    "Github",
                    "Travis CI",
                ],
            },
        ],
    },
    education: {
        header: "Work Experience and Education",
        subHeader: "My previous jobs and qualifications",
        cards: [
            {
                title: "NAB",
                subTitle: "Intern Software Engineer",
                location: "Melbourne, Australia",
                para: [
                ],
                date: "JUL/21 - PRESENT",
                icon: "work",
            },
            {
                title: "M. Brodribb Pty Ltd",
                subTitle: "Data Entry Specialist",
                location: "Melbourne, Australia",
                para: [
                    "Review and processing of staff timesheets",
                    "Purchase order receipting",
                    "Management of creditor invoices and account balances",
                    "Creditor and debtor liaising",
                ],
                aside: "M. Brodribb is a specialist engineering firm that designs and manufactures high-voltage conversion and cathodic protection systems.",
                date: "FEB/18 - JUN/21",
                icon: "work",
            },
            {
                title: "Synergo Teknologi",
                subTitle: "Software Development Internship",
                location: "Jakarta, Indonesia",
                para: [
                    "Self-driven research, design, implementation and testing of web-based admin facing componentry",
                    "Interfacing and collaboration with internationally based engineering team",
                    "Code base review and refactor of string literals"
                ],
                aside: "Based in Jakarta, Indonesia; Synergo Teknologi is a SaaS company that develops and markets business management products and solutions.",
                date: "NOV/18 - FEB/19",
                icon: "work",
            },
            {
                title: "Malvern Rover Scout Crew",
                subTitle: "Chairman",
                location: "Melbourne, Australia",
                para: [
                    "Chair bi-monthly board meetings",
                    "Oversight of day-to-day running and operations",
                    "Responsible for the safety and wellbeing of members during crew events",
                ],
                aside: "Malvern Rover Crew (MRC) is a member of Scouting Australia, with 20+ current members, MRC is an active part of the local community.",
                date: "JUL/20 - JUL/21",
                icon: "work",
            },
            {
                title: "RMIT University",
                subTitle: "Bachelor of Engineering (Computer Systems and Networking) with Honours/Bachelor of Computer Science",
                location: "Melbourne, Australia",
                date: "MAR/16 - PRESENT",
                icon: "school",
            },
            {
                title: "The King David School",
                subTitle: "Year 12",
                location: "Melbourne, Australia",
                date: "NOV/14",
                icon: "school",
            }
        ]
    },
    hobbies: {
        header: "Hobbies",
        subHeader: "What I do, when I'm not at a computer",
        data: [
            {
                image: Snowboard,
                text: "Snowboarding",
                flipDirection: "vertical",
            },
            {
                image: Yoga,
                text: "Yoga",
                flipDirection: "horizontal",
            },
            {
                image: Bicycle,
                text: "Cycling",
                flipDirection: "vertical",
            },
            {
                image: Camping,
                text: "Camping",
                flipDirection: "horizontal",
            }
        ]
    },
    contactForm: {
        header: "Contact",
        subHeader: "Wanting to get in touch?",
    }
}
