import React from "react"

import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import PhoneIcon from "@material-ui/icons/Phone"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import GitHubIcon from "@material-ui/icons/GitHub"

import { MAIL_LINK, TEL_LINK, URLS } from "../../strings/urls"

export const SocialLinkData = [
  {
    href: URLS.PAPER_RESUME,
    icon: CloudDownloadIcon,
    tip: "CV",
    color: "second",
  },
  {
    href: URLS.LINKEDIN_PROFILE,
    icon: LinkedInIcon,
    tip: "LinkedIn",
    color: "first",
  },
  {
    href: URLS.GITHUB_PROFILE,
    icon: GitHubIcon,
    tip: "GitHub",
    color: "fifth",
  }
]

export const NavLinkData = [
  ...SocialLinkData,
  {
    href: TEL_LINK,
    icon: PhoneIcon,
    tip: "Mobile",
    color: "fourth",
  },
  {
    href: MAIL_LINK,
    icon: MailOutlineIcon,
    tip: "Email",
    color: "third",
  },
]
