import React from 'react'
import PropTypes from "prop-types"
import ReactCardFlip from "react-card-flip";
import useHover from "@react-hook/hover";
import { useInView } from 'react-intersection-observer';

import { Grid, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    card: {
        width: "260px",
        height: "260px",
        borderRadius: "4px",
        boxShadow: "0px 30px 100px -10px rgba(0, 0, 0, 0.4)",
        margin: "0 auto",
    },
    cardContainer: {
        margin: theme.spacing(2, 0),
    },
    container: {
        height: "100%",
        width: "100%",
    },
    image: {
        width: "80%",
        height: "80%",
        margin: "10%",
    },
    text: {
        color: theme.palette.grey["800"],
        textTransform: "uppercase",
    }
}))

export default function OnHoverFlipCard(props) {
    const {frontImage, backText, flipDirection, color} = props
    const classes = useStyles()

    const target = React.useRef([])
    const isHovering = useHover(target, {enterDelay: 150, leaveDelay: 100})
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 1,
    });


    const flipped = inView ? isHovering : true

    return (
      <div ref={target}>
          <div className={classes.cardContainer} ref={ref}>
              <ReactCardFlip  isFlipped={flipped} flipDirection={flipDirection}>
                  <div className={classes.card} style={{background: color}}>
                      <img alt={''} src={frontImage} className={classes.image}/>
                  </div>

                  <div className={classes.card} style={{background: color}}>
                      <Grid
                        container
                        direction={"column"}
                        justify={"center"}
                        alignItems={"center"}
                        className={classes.container}
                      >
                          <Grid item>
                              <Typography
                                variant={"h5"}
                                component={"p"}
                                className={classes.text}
                              >
                                  <strong>
                                      {backText}
                                  </strong>
                              </Typography>
                          </Grid>
                      </Grid>
                  </div>
              </ReactCardFlip>
          </div>
      </div>
    )
}

OnHoverFlipCard.propTypes = {
    frontImage: PropTypes.string,
    backText: PropTypes.string,
    flipDirection: PropTypes.string,
}
