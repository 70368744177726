import React from 'react'
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

import {
    Grid,
    Typography,
    useTheme,
    makeStyles, useMediaQuery
} from "@material-ui/core"
import StarIcon from '@material-ui/icons/Star';

import BlockButton from "../../components/Buttons/BlockButton";

const useStyles = makeStyles(theme => ({
    root: {
        '&::before': {
            background: `${theme.palette.text.disabled}!important`
        }
    },
    button: {
        color: theme.palette.type === 'dark'
            ? theme.palette.grey["200"]
            : theme.palette.grey["900"],
        "&:hover": {
            color: theme.palette.type === 'dark'
              ? theme.palette.accents.first.main
              : theme.palette.accents.first.dark,
        },
    },
    buttonMargin: {
        marginTop: theme.spacing(.75),
        marginRight: theme.spacing(1),
    },
    date: {
        fontWeight: "600!important",
        fontSize: "1rem!important",
        color: theme.palette.type === 'dark'
          ? theme.palette.grey["200"]
          : theme.palette.grey["800"],
    },
}));

export default function Projects({ cards }) {
    const classes = useStyles()
    const theme = useTheme()
    const isLgDown = useMediaQuery(theme.breakpoints.down('md'))
    const isThemeDark = theme.palette.type === 'dark'
    const contentFilter = isThemeDark
      ? theme.palette.accents.first.dark
      : theme.palette.accents.first.main
    const buttonColor = isThemeDark
      ? theme.palette.grey["800"]
      : theme.palette.grey["300"]

    return (
      <VerticalTimeline animate={!isMobile} className={classes.root} layout={isLgDown ? '1-column-right' : '2-columns'}>
          {cards.map((ele, key) => {
              const button = (text) => (
                <Grid item className={classes.buttonMargin}>
                    <BlockButton accentColor={buttonColor} colorHover href={ele.href1}>
                        <Typography
                          variant={"caption"}
                          className={classes.button}
                        >
                            <strong>

                                {text}
                            </strong>
                        </Typography>
                    </BlockButton>
                </Grid>
              )
              return (
                <VerticalTimelineElement
                  contentStyle={{background: contentFilter}}
                  date={ele.date}
                  dateClassName={classes.date}
                  iconStyle={{background: theme.palette.grey["700"], color: theme.palette.grey["200"]}}
                  icon={<StarIcon />}
                  key={key}
                >
                    <Typography
                      variant={"h6"}
                      color={"textPrimary"}
                    >
                        <strong>
                            {ele.title}
                        </strong>
                    </Typography>
                    <Typography
                      variant={"subtitle1"}
                      color={"textSecondary"}
                    >
                        {ele.subTitle}
                    </Typography>
                    <Typography
                      variant={"body2"}
                      color={"textPrimary"}
                    >
                        {ele.para}
                    </Typography>
                    {(ele.href1 || ele.href2) && (
                      <Grid
                        container
                        direction={"row"}
                      >
                          {ele.href1 && button(ele.text1)}
                          {ele.href2 && button(ele.text2)}
                      </Grid>
                    )}
                </VerticalTimelineElement>
              )
          })}
      </VerticalTimeline>
    )
}

Projects.propTypes = {
    cards: PropTypes.array.isRequired,
}
