import React from 'react'
import PropTypes from "prop-types"

import { Grid, Typography, makeStyles, useMediaQuery, Container } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => ({
    large: {
        width: theme.spacing(30),
        height: theme.spacing(30),
        margin: theme.spacing(8, 0),
    },
}))

export default function AboutMe({ data, avatar }) {
    const classes = useStyles()
    const isSmDown = useMediaQuery(theme => theme.breakpoints.down("md"))
    return (
      <Grid
        container
        justify={isSmDown ? "center" : "space-between"}
        alignItems={"center"}
      >
          <Grid item>
              <Container maxWidth={"md"} disableGutters>
                  <Typography
                    variant={"h6"}
                    color={"textPrimary"}
                  >
                      <strong>
                          {data.header}
                      </strong>
                  </Typography>
                  <Typography
                    variant={"subtitle1"}
                    color={"textSecondary"}
                    gutterBottom
                  >
                      {data.subHeader}
                  </Typography>
                  <br/>
                  <br/>
                  {data.para.map(ele =>
                    <Typography
                      key={ele}
                      variant={"body2"}
                      align={"left"}
                      color={"textPrimary"}
                    >
                        {ele}
                    </Typography>
                  )}
              </Container>
          </Grid>
          <Grid item>
              <Avatar
                src={avatar}
                className={classes.large}
              />
          </Grid>
      </Grid>
    )
}

AboutMe.propTypes = {
    data: PropTypes.object.isRequired,
    avatar: PropTypes.string.isRequired,
}
