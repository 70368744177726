import React from 'react'
import { useForm } from '@formspree/react';

import {
    Button,
    Grid,
    TextField,
    Typography,
    makeStyles,
    withStyles
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    button: {
        boxShadow: theme.shadows[4],
        borderRadius: theme.shape.borderRadius,
        padding: "12px 30px",
        margin: theme.spacing(1, 0),
        textTransform: "uppercase",
        backgroundColor: theme.palette.type === 'dark'
          ? theme.palette.accents.first.main
          : theme.palette.accents.second.main,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.type === 'dark'
              ? theme.palette.accents.second.main
              : theme.palette.accents.first.main,
        }
    },
}))

const ThemeTextField = withStyles(theme => ({
    root: {
        '& label, & label.Mui-focused, & input, & textarea': {
            color: theme.palette.type === "dark"
              ? theme.palette.grey["200"]
              : theme.palette.grey["900"],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: "4px!important",
                borderColor: theme.palette.type !== 'dark'
                  ? theme.palette.accents.second.main
                  : theme.palette.accents.first.main,
            },
            '&:hover fieldset, &.Mui-focused fieldset': {
                borderColor: theme.palette.type !== 'dark'
                  ? theme.palette.accents.first.main
                  : theme.palette.accents.second.main,
            },
        },
        margin: theme.spacing(1, 0),
        width: "90vw",
        maxWidth: "450px",
    },
}))(TextField);

export default function ContactForm() {
    const classes = useStyles();
    const [state, handleSubmit] = useForm("mwkwgnwy");
    const [emailState, setEmailState] = React.useState('');
    const [messageState, setMessageState] = React.useState('');

    const handleChange = (event) => {
        if (event.target.name === "email") setEmailState(event.target.value);
        else if (event.target.name === "message") setMessageState(event.target.value);
    };

    const formProps = [
        {
            required: true,
            id:"email",
            type:"email",
            name:"email",
            label:"Email",
            variant:"outlined",
            value: emailState,
            onChange: handleChange
        },
        {
            required: true,
            id: "message",
            name: "message",
            label: "Message",
            multiline: true,
            rows: 4,
            variant: "outlined",
            value: messageState,
            onChange: handleChange
        },
    ]

    const form = (
      <>
          {formProps.map(value =>
            <Grid item key={value.id}>
                <ThemeTextField {...value}/>
            </Grid>
          )}
          <Grid item>
              <Button
                type="submit"
                className={classes.button}
              >
                  <Typography
                    variant={"button"}
                    color={"textSecondary"}
                  >
                      <strong>
                          Submit
                      </strong>
                  </Typography>
              </Button>
          </Grid>
      </>
    );
    const successMessage = (
      <Grid item>
          <Typography
            variant={"h6"}
            component={"p"}
            color={"textPrimary"}
          >
              Thanks! I'll be sure to get back to you soon.
          </Typography>
      </Grid>
    );

    return (
      <form onSubmit={handleSubmit}>
          <Grid
            container
            direction={"column"}
            justify={"space-evenly"}
            alignItems={"center"}
            alignContent={"center"}
          >
              {state.succeeded ? successMessage : form}
          </Grid>
      </form>
    );
}
