import React from 'react'

import DefaultHelmet from "../components/Helmet/DefaultHelmet";

import {makeStyles, useTheme} from "@material-ui/core/styles"
import {useMediaQuery} from "@material-ui/core";

import AppHeader from "../components/AppHeader/AppHeader"
import AppDrawer from "../components/AppDrawer/AppDrawer";
import AppFooter from "../components/AppFooter/AppFooter";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    backgroundColor: theme.palette.background.paper,
    margin: "0",
  }
}))

export default function DefaultPageLayout({children}) {
  const classes = useStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md))

  /* State variables */
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  /* State modifiers */
  const handleDrawerToggle = () => setDrawerOpen(prevState => !prevState)
  const handleDrawerClose = () => setDrawerOpen(false)

  /* UseEffect */
  React.useEffect(() => handleDrawerClose(), [isMdUp])
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
      <DefaultHelmet />
      <AppHeader
        handleDrawerToggle={handleDrawerToggle}
      />
      <AppDrawer
        open={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <main className={classes.main}>
        {children}
      </main>
      <AppFooter/>
    </>
  )
}
