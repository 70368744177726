import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import {
    unstable_createMuiStrictModeTheme as createMuiTheme,
    CssBaseline,
    responsiveFontSizes,
    useMediaQuery,
    ThemeProvider
} from "@material-ui/core";

import LandingPage from "./pages/LandingPage"
import NotFoundPage from "./pages/NotFoundPage";

import ScrollTriggerProvider from "./components/Providers/ScrollTriggerProvider"

import { URLS } from "./assets/strings/urls";

export default function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo( () => responsiveFontSizes(createMuiTheme({
        palette: {
            type: prefersDarkMode ? 'dark' : 'light',
            accents: {
                first: {
                    light: "#d9cbff",
                    main: "#A79AD6",
                    dark: "#776ca4",
                },
                second: {
                    light: "#ffdad0",
                    main: "#D6A89F",
                    dark: "#a47971",
                },
                third: {
                    light: "#faf6ff",
                    main: "#C7C3D6",
                    dark: "#9693a5",
                },
                fourth: {
                    light: "#ffffd9",
                    main: "#D6D1A7",
                    dark: "#a4a078",
                },
                fifth: {
                    light: "#d1fff3",
                    main: "#9FD6C0",
                    dark: "#6fa490",
                },
            }
        },
        drawer: {
            width: 240,
        },
        spacing: factor => `${0.5 * factor}rem`,
    })), [prefersDarkMode]);

    return (
      <ThemeProvider theme={theme}>
          <ScrollTriggerProvider>
              <CssBaseline/>
              <Router>
                  <Switch>
                      {/* Page Routes */}
                      <Route
                        exact
                        path={URLS.ROOT}
                        component={LandingPage}
                      />
                      <Route
                        exact
                        path={URLS.PAGE_NOT_FOUND}
                        component={NotFoundPage}
                      />

                      {/* Default Route */}
                      <Route>
                          <Redirect to={URLS.PAGE_NOT_FOUND}/>
                      </Route>
                  </Switch>
              </Router>
          </ScrollTriggerProvider>
      </ThemeProvider>
    );
}
