import React from "react";

import {
    Container,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    makeStyles
} from "@material-ui/core"

import DataLink from "../Links/ExternalLink";
import CustomTooltip from "../Tooltip/CustomTooltip"

import { email, phone_string } from "../../assets/data/global";
import { NavLinkData, SocialLinkData } from "../../assets/data/components/NavLinkData"
import { FooterData } from "../../assets/data/components/FooterData"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
    footer: {
        padding: theme.spacing(4, 0),
        backgroundColor: theme.palette.grey["900"],
        color: theme.palette.grey["50"],
        "& a": {
            color: theme.palette.grey["50"],
            opacity: ".86",
            "&:visited": {
                color: theme.palette.common.white
            },
            "&:focus, &:hover": {
                opacity: 1
            }
        },
    },
    leftItem: {
        padding: theme.spacing(4, 0),
    },
    centerItem: {
        padding: theme.spacing(4, 0),
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "flex-end",
        },
        [theme.breakpoints.up('md')]: {
            display: "flex",
            justifyContent: "center",
        },
    },
    rightItem: {
        padding: theme.spacing(4, 0),
    },
    first: {
        "&:hover": {
            color: theme.palette.accents.first.main
        }
    },
    second: {
        "&:hover": {
            color: theme.palette.accents.second.main,
        }
    },
    third: {
        "&:hover": {
            color: theme.palette.accents.third.main,
        }
    },
    fourth: {
        "&:hover": {
            color: theme.palette.accents.fourth.main,
        }
    },
    fifth: {
        "&:hover": {
            color: theme.palette.accents.fifth.main,
        }
    },
    icon: {
        transition: "all .2s ease-in-out",
        "&:hover": {
            transform: "scale(1.25)",
        }
    }
}));

export default function AppFooter() {
    const classes = useStyles();
    const isSmDown = useMediaQuery(theme => theme.breakpoints.down("sm"))

    return (
      <footer className={classes.footer}>
          <Container maxWidth={"lg"}>
              <Grid container alignItems={"center"}>
                  <Grid item xs={6} sm={6} md={4} className={classes.leftItem}>
                      <Typography
                        variant={"h6"}
                        component={"p"}
                      >
                          {FooterData.left.header}
                      </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} className={classes.centerItem}>
                      {SocialLinkData.map(ele =>
                        <React.Fragment key={ele.tip}>
                            <CustomTooltip title={ele.tip}>
                                <IconButton
                                  aria-label={`${ele.tip} button`}
                                  href={ele.href}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={clsx(classes[ele.color], classes.icon)}
                                >
                                    <ele.icon />
                                </IconButton>
                            </CustomTooltip>
                        </React.Fragment>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} className={classes.rightItem}>
                      <Grid
                        container
                        direction={"column"}
                        alignItems={isSmDown ? "flex-start" : "flex-end"}
                      >
                          <Grid item>
                              <DataLink
                                data={email}
                                mail={true}
                                label={FooterData.right.email}
                              />
                          </Grid>
                          <Grid item>
                              <DataLink
                                data={phone_string}
                                mobile={true}
                                label={FooterData.right.phone}
                                primary={true}
                              />
                          </Grid>
                      </Grid>
                  </Grid>
              </Grid>
          </Container>
      </footer>
    )
}
