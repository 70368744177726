import React from "react";
import classNames from "classnames"

import {
  AppBar, Container,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';

import CustomTooltip from "../Tooltip/CustomTooltip";

import { NavLinkData } from "../../assets/data/components/NavLinkData"

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    margin: theme.spacing(2, 0),
    "& a, button": {
      color: theme.palette.grey["50"],
      opacity: ".86",
      "&:visited": {
        color: theme.palette.common.white
      },
      "&:focus, &:hover": {
        opacity: 1
      }
    },
  },
  navBar: {
    display: "flex",
    justifyContent: "flex-end",
  },
  list: {
    listStyle: "none",
    margin: theme.spacing(0)
  },
  first: {
    "&:hover": {
      color: `${theme.palette.accents.first.main}!important`
    }
  },
  second: {
    "&:hover": {
      color: `${theme.palette.accents.second.main}!important`
    }
  },
  third: {
    "&:hover": {
      color: `${theme.palette.accents.third.main}!important`
    }
  },
  fourth: {
    "&:hover": {
      color: `${theme.palette.accents.fourth.main}!important`
    }
  },
  fifth: {
    "&:hover": {
      color: `${theme.palette.accents.fifth.main}!important`
    }
  },
  icon: {
    color: theme.palette.type === 'dark'
      ? `${theme.palette.grey["200"]}!important`
      : `${theme.palette.grey["300"]}!important`,
    filter: theme.palette.type === 'dark'
      ? ``
      : "drop-shadow( 2px 2px 1px rgba(0, 0, 0, .7))",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.25)",
    }
  },
}));

export default function AppHeader({ handleDrawerToggle }) {
  const classes = useStyles();

  return (
    <AppBar
      color={"transparent"}
      className={classes.appBar}
    >
      <Container component={"nav"} maxWidth={"lg"} className={classes.navBar}>
        <Hidden smUp implementation={"css"}>
          <IconButton
            aria-label="toggle drawer"
            onClick={handleDrawerToggle}
            className={classNames(classes.third, classes.icon)}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden xsDown implementation={"css"}>
          <Grid container component={"ul"} className={classes.list}>
            {NavLinkData.map(ele => (
              <Grid item key={ele.tip} component={"li"}>
                <CustomTooltip title={ele.tip}>
                  <IconButton
                    aria-label={`${ele.tip} button`}
                    href={ele.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classNames(classes[ele.color], classes.icon)}
                  >
                    <ele.icon />
                  </IconButton>
                </CustomTooltip>
              </Grid>
            ))}
          </Grid>
        </Hidden>
      </Container>
    </AppBar>
  );
}
